<template>
  <section id="muro">
    <div class="row">
      <div class="col-12 d-flex justify-content-end mt-3 mt-lg-0 mb-3">
        <button 
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50" 
          @click="goToCreate()">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Crear publicación
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-4 mb-3 mb-lg-0 input-search">
        <input 
            type="text" 
            placeholder="Buscar un mensaje" 
            v-model="filter.search" 
            class="form-control input-custom"
            @keypress.enter="getPosts()"
            @keyup.delete="filter.search == ''? getPosts():''"/>
            <span class="input-search-icon">
              <font-awesome-icon icon="search"/>
            </span>
      </div>
      <div class="col-12 col-lg-8">
        <div class="row">
          <div class="col-12 col-lg-4 col-xl-6 d-flex align-items-center justify-content-lg-end text-secondary">
              Filtrar por periodo:
          </div>
          <div class="col-12 col-lg-8 col-xl-6">

            <div class="row">
              <div class="col-6">
                <InputDate 
                  :max="range.date_end"
                  placeHolder="Inicio"
                  @dateEmit="captureDateStart"
                />
              </div>
              <div class="col-6">
                <InputDate 
                  :min="range.date_start" 
                  placeHolder="Término"
                  @dateEmit="captureDateEnd"
                />
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-3">

        <Loading 
          v-if="loading"
          pixeles="150px"
        />
        
        <template v-if="!loading">     
          <div class="table-container">
            <table class="table table-custom table-borderless table-custom-responsive mb-0">
              <thead>
                <tr>
                  <th scope="col">Usuario</th>
                  <th scope="col">Mensaje</th>
                  <th scope="col" class="text-center">Fecha de publicación</th>
                  <th scope="col" class="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(post, k) in paginated_posts"
                  :key="k"
                  class="relative"
                  :class="post.id_estado_fk == '1' ? '' : 'disabled-cosmetic'">
                  <td class="th th-usuario" v-if="post.usuario !== null">
                    <img 
                      class="table-custom-user-img me-2" 
                      :src="handleUserImage(post.usuario[0].imagen_perfil)">
                    {{ post.usuario[0].primer_nombre }}
                    {{ post.usuario[0].apellido_paterno }}
                  </td>
                  <td class="th th-usuario" v-if="post.perfil !== null">
                    <img 
                      class="table-custom-user-img me-2" 
                      :src="handleUserImage(post.perfil.imagen_perfil)">
                    {{ post.perfil.nombre_perfil }}
                  </td>
                  <td class="th th-mensaje">{{ post.texto }}</td>
                  <td class="th th-fecha-publi text-center">{{ dateFormatymdhours(post.fecha_creacion) }}</td>
                  <td class="th th-accion text-center">
                    <div class="dropdown dropdown-custom d-flex justify-content-center">
                      <button 
                        class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                        type="button" 
                        data-bs-toggle="dropdown">
                        <font-awesome-icon icon="ellipsis-vertical"/>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a 
                            @click="disablePostByObj(post)"
                            class="dropdown-item" 
                            href="javascript:">
                            <font-awesome-icon
                              class="pe-2"
                              :class="post.id_estado_fk  == '1' ? 'color-main' : 'color-secondary'"
                              :icon="
                                post.id_estado_fk == '1'
                                  ? ['fas', 'ban']
                                  : ['fas', 'check']"/>
                            {{  post.id_estado_fk  == "1" ? "Desactivar" : "Activar" }}
                          </a>
                        </li>
                        <li>
                          <a 
                            @click="capturarComentario(post)"
                            class="dropdown-item" 
                            href="javascript:"
                            data-bs-toggle="modal" 
                            data-bs-target="#modal-detalle-muro"
                          >
                            <font-awesome-icon class="pe-2 color-main" icon="eye"/>
                            Vista previa
                          </a>
                        </li>
                        <li>
                          <a 
                            @click="editarComentario(post)"
                            class="dropdown-item" 
                            href="javascript:">
                            <font-awesome-icon class="pe-2 color-main" icon="pen"/>
                            Editar
                          </a>
                        </li>
                        <li>
                          <a 
                            @click="eliminarComentario(post)"
                            class="dropdown-item" 
                            href="javascript:">
                            <font-awesome-icon class="pe-2 color-main" icon="trash-alt"/>
                            Eliminar
                          </a>
                        </li>
                      </ul>
                  </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="paginated_posts.length < 1" class="no-result">
              Sin resultados.
          </div>
          </div>
        </template>

      </div>
    </div>

    <div class="library-list">
    <div  class="wall-list__container">

      <div class="wall-list__container-paginador">
        <paginate v-model="pagination.actual_page" :page-count="pagination.page_count" :page-range="pagination.page_range"
        :click-handler="pagination.click_handler" :prev-text="pagination.prev_text" :next-text="pagination.next_text"
        :container-class="pagination.container_class" :page-class="pagination.page_class">
        </paginate>
      </div>
    </div>
    <Question
      v-if="open_question_modal"
      :msg="question_modal_msg"
      :hideCancel="false"
      @cancel="cancelQuestion"
      @accept="acceptQuestion"
    />
  </div>

  <ModalMuro 
    :data="comentario_actual"
    @edit="editarComentario"
    @delete="eliminarComentario"
  />

</section>
</template>

<script>
  import Question from "../Modales/Question.vue";
  import { mapActions, mapState } from "vuex";
  import moment from "moment";

  //FF
  import ModalMuro from "@/components/Wall/ModalMuro.vue";
  import InputDate from "@/components/forms/InputDate.vue"
  import Loading from "@/components/Loading.vue";

  export default {
    components: { 
        Question,

        //FF
        ModalMuro,
        InputDate,
        Loading
      },
    data() {
      return {
        open_question_modal: false,
        question_modal_msg: "",
        open_modal_status: false,
        modal_status_msg: "",
        modal_status: true,
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        months: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        id_usuario: this.$ls.get("user").id_usuario,
        obj: null,
        show_calendar: false,
        calendar_side: "",
        max_date: new Date(),
        min_date: "",
        init_date: "Inicio",
        end_date: "Término",
        img_calendar: require("@/assets/img/utilities/calendar.svg"),
        user_default_img: require("@/assets/img/user_default_img.svg"),
        pagination: {
          actual_page: 1,
          page_count: 1,
          page_range: 3,
          click_handler: this.changePage,
          prev_text:
            '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
          next_text:
            '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
          container_class: "users-list__pagination-container",
          page_class: "pagination-item",
          limit: '15',
        },
        platform: '2',
        post_to_delete: null,

        //FF
        loading: true,
        filter: { 
          search: '' 
        },
        comentario_actual: {},
        range: {
          date_start: null,
          date_end: null,
          mask_start: null,
          mask_end: null
        }
      };
    },
    async mounted() {
      this.pagination.page_count = this.pagination_data.length;
    },
    computed: {
      ...mapState("wallModule", ["wall_actions_list", "wall_action", "pagination_data", "paginated_posts"]),
      attributes() {
        return {
          highlight: true,
          color: "#009CDE",
          dates: new Date(),
        };
      },
    },
    watch: {
      pagination_data(value){
        // acá se realiza el calculo de total de las paginas, una vez cambia la variable pagination_data, se captura el largo y se le pasa 
        // a la variable this.pagination.page_count
        this.pagination.page_count = value.length;
      },

      //FF
      "range.date_start"() {
          this.getPosts();
      },
      "range.date_end"() {
          this.getPosts();    
      },
    },
    methods: {
      ...mapActions("wallModule", ["getAllPostsByPage", "deletePost", "disablePost", "getPostPaginasPostCompleto"]),
      dataStatusPost(type, post){
        let status = '';
        let data_ = {};
        if(type == 'eliminar') {
          status = '2';
          data_ = {
            id_muro: post.id_muro,
            id_usuario: this.id_usuario,
            id_empresa: this.id_empresa,
            usuario_perfil: post.usuario == null ? '2':'1',
            id_objeto: post.usuario == null ? post.perfil.id_perfil : post.usuario[0].id_usuario,
            estado: status,
          }
        } 
        else {
          status = post.id_estado_fk == '1'?'3':'1'
          data_ = {
            usuario_perfil: post.usuario == null ? '2':'1',
            id_objeto: post.usuario == null ? post.perfil.id_perfil : post.usuario[0].id_usuario,
            id_muro: post.id_muro,
            id_usuario: this.id_usuario,
            id_empresa: this.id_empresa,
            estado: status,
          }
        }  
        return data_;
      },
      goToCreate(){
        this.$router.push({name:"post-add"})
      },
      async disablePostByObj(post){
        await this.disablePost(this.dataStatusPost('editar',post)).then(res =>{
          if(res != null){
            this.$toast.open({
              message:`La publicación ha sido desactivada correctamente.`,
              type:'success',
              duration:6000,
              position:'top-right'
            })
          }else{
            this.$toast.open({
              message:`Ocurrió un error al desactivar la publicación, intentalo nuevamente.`,
              type:'error',
              duration:0,
              position:'top-right'
            })
          }
        });
      },
      async deletePostByObj(post){
        await this.deletePost(this.dataStatusPost('eliminar', post)).then(res =>{
          if(res != null){
            this.$toast.open({
              message:`La publicación ha sido eliminada correctamente.`,
              type:'success',
              duration:6000,
              position:'top-right'
            })
          }else{
            this.$toast.open({
              message:`Ocurrió un error al eliminar la publicación, intentalo nuevamente.`,
              type:'error',
              duration:0,
              position:'top-right'
            })
          }
        });
        this.post_to_delete = null;
        this.open_question_modal = false;
      },
      async changePage(page){
        this.pagination.actual_page = page;
        await this.getPosts();
      },
      async getPosts() {
        this.loading = true;
        let data2_ = {
          id_empresa: this.id_empresa,
          id_usuario: this.id_usuario,
          plataforma: "2",
          fecha_inicio: this.range.date_start !== null ? this.range.mask_start : '',
          fecha_final: this.range.date_end !== null ? this.range.mask_end : '',
          nombre: this.filter.search,
          limite: "10",
        };
        let pagina = this.pagination.actual_page;
        //console.log("data_ ", data2_);
        await this.getPostPaginasPostCompleto({data2_, pagina}).then(res => {
          if(res == null){
            this.$toast.open({
                message:`Ocurrió un error al obtener la información.`,
                type:'error',
                duration:0,
                position:'top-right'
              });
          }
        });
        this.pagination.page_count = this.pagination_data.length;
        this.loading = false;
      },
      handleUserImage(img) {
          if (img !== null && img !== "" && img !== undefined) return img;
          else return this.user_default_img;
        },
      openQuestionDelete(post) {
        this.post_to_delete = post;
        this.open_question_modal = true;
        this.question_modal_msg = "¿Estas seguro de eliminar esta publicación?";
      },
      cancelQuestion() {
        this.post_to_delete = null;
        this.open_question_modal = false;
      },
      acceptQuestion() {
        if(this.post_to_delete != null){
          this.deletePostByObj(this.post_to_delete);
        }
      },
      closeStatus() {
        this.open_modal_status = false;
      },
      //FF
      capturarComentario(comentario) {
        this.comentario_actual = comentario;
      },
      editarComentario(comentario) {
        this.$router.push({
          name: "post-edit",
          params: { id: comentario.id_muro },
        })
      },
      eliminarComentario(comentario) {
        this.post_to_delete = comentario;
        this.open_question_modal = true;
        this.question_modal_msg = "¿Estas seguro de eliminar esta publicación?";
      },
      captureDateStart(date) {
        this.range.date_start = date;
        this.range.mask_start = moment(date).format('YYYY-MM-DD');
      },
      captureDateEnd(date) {
        this.range.date_end = date;
        this.range.mask_end = moment(date).format('YYYY-MM-DD');
      }
    },
    async created() {
      await this.getPosts();
    }
  };
</script>
