<script>
	export default {
		props: {
			data: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
			user_default_img: require("@/assets/img/user_default_img.svg"),
			}
		},
		methods: {
			handleUserImage(img) {
				if (img !== null && img !== "" && img !== undefined) return img;
				else return this.user_default_img;
			},
			editar(data){
				this.$emit("edit", data);
			},
			eliminar(data){
				this.$emit("delete", data);
			},
		}
	}
</script>
<template>
	<div 
		class="modal fade" 
		id="modal-detalle-muro" 
		tabindex="-1" 
		aria-hidden="true"
		>
		<div class="modal-dialog modal-dialog-scrollable">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title color-main font-700"> Comentario </h3>
					<button
						type="button" 
						class="modal-custom-close"
						data-bs-dismiss="modal" 
						aria-label="Close">
						<font-awesome-icon icon="xmark"/>
					</button>
				</div>
				<div 
					v-if="Object.keys(data).length !== 0"
					class="modal-body">
					<div class="row mb-3">
						<div class="col-12">
							<div class="d-flex align-items-center">
								<div class="flex-grow-1">
									<div class="d-flex align-items-center">
										<div class="flex-shrink-0">
											<img 
												:src="handleUserImage(data.usuario[0].imagen_perfil)"
												class="img-object-cover rounded-circle"
												width="60"
												height="60"
											>
										</div>
										<div class="flex-grow-1 ms-3">
											<p class="text-secondary mb-0">
												{{ data.usuario[0].primer_nombre }} 
												{{ data.usuario[0].apellido_paterno }}
											</p>
										</div>
									</div>
								</div>
								<div class="flex-shrink-0">
									<a 
										@click="editar(data)"
										href="javascript:" 
										class="p-2 link-primary"
										data-bs-dismiss="modal" 
									>
										<i class="fa-solid fa-pen"></i>
									</a>
									<a 
										@click="eliminar(data)"
										href="javascript:" 
										class="p-2 link-primary"
									>
										<i class="fa-solid fa-trash-can"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<p class="text-secondary">{{ data.texto }}</p>
							<figure class="figure mb-0">
								<img 
									:src="data.imagen_muro" 
									class="figure-img img-fluid rounded border"
								>
							</figure>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<div class="d-flex justify-content-end align-items-center text-secondary">
								<i class="fa-regular fa-comment me-1 color-main"></i>
								{{ data.comentarios_compartidos == null ? '0' :  data.comentarios_compartidos.length }}
								<i class="fa-solid fa-heart color-red ms-2 me-1"></i>
								{{ data.likes.length }}
							</div>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-12">
							<div 
								v-for="(coment, i) in data.comentarios_compartidos" :key="i"
								class="card">
								<div class="card-body">
									<p>
										<strong>
											{{ coment.usuario[0].primer_nombre }} 
											{{ coment.usuario[0].apellido_paterno }}:
										</strong>
										{{ coment.texto }}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>